<template>
  <div>
    <b-card>
      <div>
        <h1>{{ $t('complaints.complaint_list.title') }}</h1>
      </div>
      <hr>
      <div>
        <app-collapse>
          <app-collapse-item :title="$t('complaints.complaint_list.filters.title')">
            <b-card-body>
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <!-- Nom ou prénom ou mail du plaignant -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.name')"
                          label-for="client"
                          label-cols-md="12"
                        >
                          <b-form-input
                            id="client"
                            v-model="filters.name"
                            type="text"
                            :placeholder="$t('complaints.complaint_list.filters.name_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Métier -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.sector')"
                          label-for="sector"
                          label-cols-md="12"
                        >
                          <v-select
                            id="sector"
                            v-model="filters.sector"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="listMetiers"
                            label="title"
                            :reduce="(option) => option.id"
                            :placeholder="$t('complaints.complaint_list.filters.sector_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Objet de la plainte -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.object')"
                          label-for="object"
                          label-cols-md="12"
                        >
                          <v-select
                            id="object"
                            v-model="filters.object"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="listObjets"
                            label="title"
                            :reduce="(option) => option.title"
                            :placeholder="$t('complaints.complaint_list.filters.object_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Message de le plainte -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.message')"
                          label-for="message"
                          label-cols-md="12"
                        >
                          <b-form-input
                            id="message"
                            v-model="filters.message"
                            type="text"
                            :placeholder="$t('complaints.complaint_list.filters.message_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Profil -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.company')"
                          label-for="profils"
                          label-cols-md="12"
                        >
                          <v-select
                            id="profils"
                            v-model="filters.profile_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="listProfils"
                            :reduce="(option) => option.id"
                            label="nom_etablissement"
                            :placeholder="$t('complaints.complaint_list.filters.company_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Status de la plainte -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.status')"
                          label-for="profils"
                          label-cols-md="12"
                        >
                          <v-select
                            id="profils"
                            v-model="filters.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :reduce="(option) => option.alias"
                            label="label"
                            :options="listStatus"
                            :placeholder="$t('complaints.complaint_list.filters.status_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Période -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('complaints.complaint_list.filters.periode')"
                          label-for="periode"
                          label-cols-md="12"
                        >
                          <flat-pickr
                            id="periode"
                            v-model="filters.periode"
                            class="form-control"
                            :config="{ mode: 'range' }"
                            :placeholder="$t('complaints.complaint_list.filters.periode_placeholder')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div class="text-right mt-2">
                  <b-button
                    type="submit"
                    variant="warning"
                    @click="searchPlainte"
                  >
                    <b-spinner
                      v-if="isLoading"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="SearchIcon"
                      class="mr-25"
                    />
                    &nbsp; {{ $t('button.search') }}
                  </b-button>
                </div>
              </b-form>
            </b-card-body>
          </app-collapse-item>
        </app-collapse>
      </div>
    </b-card>
    <!-- Table Container Card -->
    <b-card>
      <div class="m-2" />
      <b-table
        ref="refTableListPlaintes"
        class="position-relative"
        :items="tableData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('table_columns.empty_text')"
        :busy="isLoading"
        hover
      >
        <!-- Custom Header -->
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(secteur)="data">
          {{ $t(data.item.profile.secteur.title) }}
        </template>

        <template #cell(object)="data">
          <b-link
            class="font-weight-bold"
            @click="goToDetails(data.item.id)"
          >
            <!-- {{ data.item.object }} -->
            {{ data.item.object.length > 30 ? data.item.object.slice(0, 30) + '...' : data.item.object }}
          </b-link>
        </template>

        <template #cell(profile)="data">
          {{ data.item.profile.nom_etablissement }}
        </template>

        <template #cell(status)="data">
          <div v-if="data.item.status === 'comment'">
            <b-badge variant="secondary">
              {{ $t('complaints.complaint_list.status.comment') }}
            </b-badge>
          </div>
          <div v-if="data.item.status === 'not_treated'">
            <b-badge variant="warning">
              {{ $t('complaints.complaint_list.status.not_treated') }}
            </b-badge>
          </div>
          <div v-if="data.item.status === 'in_treatment'">
            <b-badge variant="success">
              {{ $t('complaints.complaint_list.status.in_treatment') }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="$can('show', 'complaint')"
            variant="primary"
            class="mr-1"
            @click="goToDetails(data.item.id)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-25"
            />
            <span class="text-nowrap d-none d-md-none d-lg-inline">{{ $t('button.see') }}</span>
          </b-button>
          <!-- <b-button
            v-if="$can('delete', 'complaint')"
            variant="danger"
            @click="wouldDelete(data.item.id)"
          >
            <i class="las la-trash-restore mr-25" />
            <span class="text-nowrap d-none d-md-none d-lg-inline">{{ $t('button.remove') }}</span>
          </b-button> -->
        </template>
      </b-table>
    </b-card>

    <!-- Modal de suppression de la plainte -->
    <b-modal
      id="modal-delete-ticket"
      ref="modal-delete-ticket"
      :title="$t('complaints.complaint_list.modal_remove.title')"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      modal-class="modal-danger"
      :ok-title="$t('complaints.complaint_list.modal_remove.button_accept')"
      :cancel-title="$t('complaints.complaint_list.modal_remove.button_cancel')"
      no-close-on-backdrop
      @ok="deleteManuel"
    >
      <div>
        <b-card-text>
          <h5>{{ $t('complaints.complaint_list.modal_remove.text') }}</h5>
        </b-card-text>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BLink,
  BModal,
  VBModal,
  BBadge,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import etablissementsStoreModule from '@/store/etablissements'
// eslint-disable-next-line import/no-cycle
import statusStoreModule from '@/store/status'
// eslint-disable-next-line import/no-cycle
import plaintesStoreModules from '@/store/plaintes'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

import PaginationComponent from '@/components/PaginationComponent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    // ProductsListFilters,
    // UserListAddNew,

    BCard,
    BCardBody,
    BRow,
    BCardText,
    // BCollapse,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BSpinner,
    BModal,
    vSelect,
    PaginationComponent,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
  },

  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },

  data() {
    return {
      isLoading: false,
      tableData: [],
      tableColumns: [
        { key: 'fullname', label: 'table_columns.fullname', sortable: true },
        { key: 'secteur', label: 'table_columns.sector', sortable: true },
        {
          key: 'object',
          label: 'table_columns.object',
          sortable: true,
          thStyle: { width: '20%' },
        },
        { key: 'profile', label: 'table_columns.company', sortable: true },
        { key: 'status', label: 'table_columns.status', sortable: true },
        { key: 'created_at', label: 'table_columns.date', sortable: true },
        { key: 'actions', label: 'table_columns.actions' },
      ],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      plainteId: null,
      filteredOptions: [],
      listProfils: [],
      listStatus: [],
      listMetiers: [],
      listObjets: [
        { id: '1', title: 'Retard du moyen de transport' },
        { id: '2', title: 'Service client irrespectueux' },
        { id: '3', title: 'Problèmes de réservation' },
        { id: '4', title: 'Guide mal informé' },
        { id: '5', title: 'Itinéraire de visite insatisfaisant' },
        { id: '6', title: 'Guide irrespectueux ou désintéressé.' },
        { id: '7', title: 'Réservation annulée sans préavis' },
        { id: '8', title: 'Problèmes de facturation ou de paiement' },
        { id: '9', title: 'Bruit excessif' },
        { id: '10', title: 'Service lent ou inattentif' },
        { id: '11', title: 'Commande incorrecte' },
        { id: '12', title: "Problèmes d'hygiène ou de salubrité" },
      ],
      filters: {
        name: null,
        sector: null,
        object: null,
        message: null,
        profile_id: null,
        status: null,
        periode: null,
      },
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.loadProfils()
        await this.loadMetiers()
        await this.loadStatus()
        await this.searchPlainte()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.tableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1),
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.searchPlainte()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.searchPlainte()
      },
    },
  },

  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStoreModule },
      { path: 'status', module: statusStoreModule },
      { path: 'plaintes', module: plaintesStoreModules },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('etablissements', {
      action_searchEtablissement: 'searchEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    ...mapActions('status', {
      action_fetchStatus: 'fetchStatus',
    }),
    ...mapActions('plaintes', {
      action_searchPlainte: 'searchPlainte',
      action_deletePlainte: 'deletePlainte',
    }),
    // Chargement des profils
    async loadProfils() {
      try {
        const response = await this.action_searchEtablissement()
        if (response.status === 200) {
          this.listProfils = response.data.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des profils', error)
      }
    },
    // Chargement des métiers
    async loadMetiers() {
      try {
        const response = await this.action_getListTypesEtablissement()
        if (response.status === 200) {
          this.listMetiers = response.data.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des métiers', error)
      }
    },
    // Chargement des status
    async loadStatus() {
      try {
        const response = await this.action_fetchStatus()
        if (response.status === 200) {
          this.listStatus = response.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des status', error)
      }
    },
    // Recherche des plaintes
    async searchPlainte() {
      try {
        this.isLoading = true

        let periodeFormatted = null
        if (this.filters.periode && this.filters.periode !== '') {
          const periodeArray = this.filters.periode.split(' au ')
          if (periodeArray.length === 2) {
            periodeFormatted = {
              from: periodeArray[0],
              to: periodeArray[1],
            }
          } else if (periodeArray.length === 1) {
            periodeFormatted = {
              from: periodeArray[0],
              to: periodeArray[0],
            }
          }
        }

        const response = await this.action_searchPlainte({
          name: this.filters.name,
          sector: this.filters.sector,
          object: this.filters.object,
          message: this.filters.message,
          profile_id: this.filters.profile_id,
          status: this.filters.status,
          periode: periodeFormatted,
          // params for filter by pagination
          page: this.paginationData.currentPage,
          per_page: this.paginationData.perPage,
        })
        if (response.status === 200) {
          this.tableData = response.data.data
          console.log(this.tableData)
          this.isLoading = false
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }
          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + response.data.meta.total
          // --------------------------------------------------------------
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        this.isLoading = false
        console.error(
          'Erreur lors du chargement des données de la recherche de plainte',
          error,
        )
      }
    },
    // Changement de page
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
    // Redirection vers la page de détails
    goToDetails(id) {
      localstorageService.setPlainteId(id)
      this.$router.push({ name: 'plainte-details', params: { id } })
    },
    // Demande de consentement
    wouldDelete(id) {
      this.plainteId = id
      this.$refs['modal-delete-ticket'].show()
    },
    // Suppression d'une plainte
    async deleteManuel() {
      this.isLoading = true
      try {
        const response = await this.action_deletePlainte(this.plainteId)
        if (response.status === 204) {
          this.isLoading = false
          this.$router.replace({ name: 'plainte-list' })
          await this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Suppresion réussie',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        } else {
          console.log("La réponse n'a pas un status HTTP 204")
        }
      } catch (error) {
        this.isLoading = false
        console.log('Erreur lors de la plainte', error)
        await this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Une erreur est survenue',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
